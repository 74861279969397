
import { useNavigate } from "react-router-dom";
import "./AboutPage.scss"
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import CredentialsLeft from '../../assets/credentialsleft.webp';
import CredentialsRight from '../../assets/credentialsright.webp';
import DefinitionItem from "../../components/DefinitionItem/DefinitionItem";
import { useState } from "react";
import { Helmet } from "react-helmet";
const AboutPage = () => {
    const navigate = useNavigate();
    const [nazDefs, setNavDefs] = useState([
        {
            title: "What are the nazarenes?",
            contents: [
                `The Nazarenes (or Nazoreans; Greek: Ναζωραῖοι, Nazōraioi; Hebrew: נָּצְרִים, Natzerim) were an early Jewish Christian sect in first-century Judaism. The first use of the term is found in the Acts of the Apostles (Acts 24:5) of the New Testament, where Paul the Apostle is accused of being a ringleader of the sect of the Nazarenes ("πρωτοστάτην τε τῆς τῶν Ναζωραίων αἱρέσεως") before the Roman procurator Antonius Felix at Caesarea Maritima by Tertullus. At that time, the term simply designated followers of Jesus of Nazareth, as the Hebrew term נוֹצְרִי‎ (nôṣrî), and the Arabic term نَصَارَى (naṣārā), still do.
                As time passed, the term came to refer to a sect of Jewish Christians who continued to observe the Torah along with Noachide gentiles who were grafted in to the covenant, in contrast to gentile Christians who eschewed Torah observance. They are described by Epiphanius of Salamis and are mentioned later by Jerome and Augustine of Hippo. The writers made a distinction between the Nazarenes of their time and the "Nazarenes" mentioned in Acts 24:5.`,
            ]
        },
        {
            title: "Behind the Hebrew nature of the name:",
            contents: [
                `נֵ֣צֶר • (nétser) m (plural indefinite נְצָרִים, singular construct נֵצֶר־, plural construct נִצְרֵי־)`,
                `1. branch, sprout`,
                `2. (botany) a detached shoot or twig containing buds from a woody plant, used in grafting; a shoot or twig in a general sense`,
                `(literary, collectively) scion, descendant, heir to a throne or kingdom`
            ]
        },
        {
            title: "The beliefs of the Nazarene sect or sects are described through various church fathers and heresiologists:",
            contents: [
                `in Jesus as Messiah: "The Nazarenes... accept Messiah in such a way that they do not cease to observe the old Law."
                — Jerome, On. Is. 8:14
                `,
                `in the Virgin Birth: "They believe that the Messiah was born of the Virgin Mary."
                — Jerome, Letter 75 Jerome to Augustine`,
                `Adhering to circumcision and the Law of Moses: "They disagree with Jews because they have come to faith in Christ; but since they are still fettered by the Law – circumcision, the Sabbath, and the rest – they are not in accord with the Christians."
                — Epiphanius of Salamis, Panarion 29.7.4`,
                `Use of Old Testament and New Testament: "They use not only the New Testament but the Old Testament as well, as the Jews do."
                — Epiphanius of Salamis, Panarion 29.7.2`,
                `Use of Hebrew and Aramaic New Testament source texts: "They have the Gospel according to Matthew in its entirety in Hebrew. For it is clear that they still preserve this, in the Hebrew alphabet, as it was originally written."
                — Epiphanius of Salamis, Panarion 29.9.4`,
                `"And he Hegesippus the Nazarene quotes some passages from the Gospel according to the Hebrews and from the Syriac [the Aramaic], and some particulars from the Hebrew tongue, showing that he was a convert from the Hebrews, and he mentions other matters as taken from the oral tradition of the Jews."
                — Eusebius, Church History 4.22`
            ]
        }
    ])
    const [definitions, setDefinitions] = useState([
        {
            title: `As your Dietitian, it is my goal and desire to see you live a healthy and holistic life. My goal is to provide consistency to enjoy your life with wellness and provide peace of mind to your health and lifestyle choices.`,
            contents: [
                `If you are healthy and desire to improve on your health, choosing OW.PL will maintain this in your profile. If for any reason you find yourself in the hospital, I would be able to assist the medical team with concrete information.`,
                `If you are a recent medical patient that has learned of a new condition, I am able to counsel and educate you to make informed decisions to alleviate these concerns that conforms to your primary care providers.`,
                `If you are interested in improving your current lifestyle, I am able to provide improvement and motivational plans that are instructive and simple that will build confidence and consistency in your diet and lifestyle to prevent the severity of possible risks related conditions.`,
            ]
        },
        {
            title: `Choosing OW.PL will assist in the ministerial work that will accomplish the Mission Statement of reducing malnutrition in the community I am serving. These objectives are carried out by mandatory tithing on each of our services to promote the efficacy of our mission.`,
            contents: [
                `Whether a patient or client is an OW.PL recipient, the mission is to reduce malnutrition in the community.`,
                `Pertaining to the downtrodden, orphans, and widows, those who do not have the opportunity to have meals consistent to meet their nutritional and medical needs, may benefit from OW.PL services. It is the goal that these individuals receive compassionate care so that two objectives are accomplished`,
                `=> - Prevent unnecessary death, stress and pressure, on our community's resources`,
                `=> - To edify and build up an efficient and effective community that removes such depravities through our ministerial work.`,
            ]
        },
        {
            title: `As needed, OW.PL will provide referrals to other medical professionals who will be able to facilitate the further progression of this mission in all respects.`,
            contents: [

            ]
        }
    ]);
    return (
        <div className={"about-page-container"}>
            <Helmet>
                <title>Phoenix Lounge - About</title>
                <meta name="robots" content="index, follow" />
                <meta
                    name="description"
                    content="Phoenix Lounge is a mobile wellness center focused on reducing malnutrition by servicing the community with patient-centered care and the love of Christ."
                />
                <meta name="twitter:title" content="Phoenix Lounge - About" />
                <meta name="twitter:description" content="Phoenix Lounge is a mobile wellness center focused on reducing malnutrition by servicing the community with patient-centered care and the love of Christ." />
                <meta property="og:title" content="Phoenix Lounge - About" />
                <meta property="og:description" content="Phoenix Lounge is a mobile wellness center focused on reducing malnutrition by servicing the community with patient-centered care and the love of Christ." />
            </Helmet>
            <h1>About Us</h1>
            <h2>Mission</h2>
            <p>To Reduce Malnutrition in the serviced Community with Patient-Centered Care with our salvation in Christ.</p>
            <h2>
                Why Choose the Phoenix Lounge and Omni-Wellness as your provider for Medical Nutrition Therapy?
            </h2>
            {
                definitions.map((definition)=>(<DefinitionItem definition={definition}/>))
            }
            <h2>Introduction</h2>
            <p>Johnny Dean, RD, LDN is a Registered and Licensed Texas Dietitian and Nutritionist from Arizona State University providing Restorative Wellness therapies along with Medical Nutrition Therapy.</p>
            <p>The Phoenix Lounge's Objective: Reducing Malnutrition in the DFW community and remote areas providing Christ-Centered Medical Nutrition Therapy.</p>
            <p>I use Evidence-Based Practice guidelines in-line with the Academy of Nutrition and Dietetics to meet individualized patient/client goals and needs.</p>
            <p>I am a minister in the way of the Nazarenes. I precept Interns as chefs, nutritionists, and disciples of Christ.</p>
            <h2>About the ministry</h2>
            <p>Providing Omni-Wellness Restorative Therapies to the Homeless in the D/FW communities</p>
            {
                nazDefs.map((definition)=>(<DefinitionItem definition={definition}/>))
            }
            <Button variant={"outlined"} sx={{textDecoration: 'none'}}><Link to="https://discord.gg/yrX6tseHbB" target="_blank" className="join-community-btn">Join the community</Link></Button>
            <h2>Credentials</h2>
            <p>Check out my Resume <a target="_blank" href="https://docs.google.com/document/d/1Bt0MQAgWEDE3fDafp01bSsdFFjOcaPgk/edit">Here</a></p>
            <Link to="https://www.tdlr.texas.gov/diet/laws-rules.htm"><div className="credential-images">
                <img src={CredentialsLeft} />
                <img src={CredentialsRight} />
            </div></Link>
            <h3>Questions? <Button onClick={()=>{navigate("/contact-us")}} variant={"contained"}>Contact Us</Button></h3>
        </div>
    )
};

export default AboutPage;