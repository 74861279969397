import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import "./VerifyEmailPage.scss";
import { Button } from "@mui/material";
import { postAccessApi } from "../../apis/helpers";
import * as api from '../../apis/';
import { useSnackbar } from "notistack";
import { UserInfoContext } from "../../utils/context";

const VerifyEmailPage = () => {
    const {code} = useParams();
    const [myCode, setMyCode] = useState('');
    const [resent, setResent] = useState(false);
    const { userInfo, setUserInfo } = useContext(UserInfoContext);
    const navigate = useNavigate();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const handleClick = () => {
        api.helpers.postAccessApi(api.routes.verifyEmail+(myCode), {code: myCode}, (data:any)=>{
            closeSnackbar();
            enqueueSnackbar("Your email has been verified successfully", {variant: 'success'});
            setUserInfo({...userInfo, permissions: "1"+userInfo.permissions.substring(1,10)});
            const searchParams = new URLSearchParams(document.location.search);
            const returnUrl = searchParams.get('returnUrl')
            if(!returnUrl) {
                if(userInfo.permissions[1]==="1")
                    navigate('/');
                else
                    navigate('/user/add-user-info')
            }
            else{
                navigate(returnUrl);
            }
        }, (data:any)=>{
            closeSnackbar();
            enqueueSnackbar("Verification code is invalid or expired", {variant: 'warning'});
        })
    }
    const handleResend = () => {
        setResent(true);
        api.helpers.postAccessApi(api.routes.sendEmailVerification, {}, (data:any)=>{
            closeSnackbar();
            enqueueSnackbar("Check your email for new code.", {variant: 'success'});
            enqueueSnackbar("Be sure to check your spam folder.", {variant: 'success'});
        }, (data:any)=>{
            closeSnackbar();
            enqueueSnackbar("Could not send email at this time.", {variant: 'error'});
            setResent(false);
        });
    }
    useEffect(()=>{
        setMyCode(code?code:'');
    },[])
    return (
        <div className="verify-email-page-container">
            <div className="verify-form">
                <Button variant="outlined" disabled={resent} onClick={handleResend}>Resend Verification Code.</Button>
                {resent?(<p className="sent-message">Email sent.</p>):<br />}
                <br />
                <div className="inputBox">
                    <input type="text" value={myCode} onChange={(e)=>setMyCode(e.target.value)}/>
                    <span>Verification Code:</span>
                </div>
                <Button variant="contained" onClick={handleClick}>Verify My Email</Button>
            </div>
        </div>
    )
}

export default VerifyEmailPage;