import BlueberryIcon from "../../assets/svg/Blueberry"
import RaspberryIcon from "../../assets/svg/Raspberry"
import "./Soundbite.scss";

const Soundbite = ({firstText, secondText, className}: any) => {
    return (
        <div className={"soundbite"+(className?(" ")+className:"")}>
            <div className={"soundbite-container"}>
                <div>
                    <span><BlueberryIcon />{firstText}</span>
                </div>
                <div>
                    <span>{secondText}<RaspberryIcon /></span>
                </div>
            </div>
        </div>
    )
}

export default Soundbite;