export const assessmentReportData = {
  "NI": {
    "value": "INTAKE",
    "children": {
      "NI-1": {
        "value": "Energy Balance",
        "children": {
          "1": {
            "value": "Increased energy expenditure",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Inadequate energy intake",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Excessive energy intake",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Predicted inadequate energy intake",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Predicted excessive energy intake",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "NI-2": {
        "value": "Oral or Nutrition Support Intake",
        "children": {
          "1": {
            "value": "Inadequate oral intake",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Excessive oral intake",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Inadequate enteral nutrition infusion",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Excessive enteral nutrition infusion",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Enteral nutrition composition inconsistent with needs",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "6": {
            "value": "Enteral nutrition administration inconsistent with needs",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "7": {
            "value": "Inadequate parenteral nutrition infusion",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "8": {
            "value": "Excessive parenteral nutrition infusion",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "9": {
            "value": "Parenteral nutrition composition inconsistent with needs",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "10": {
            "value": "Parenteral nutrition administration inconsistent with needs",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "11": {
            "value": "Limited food acceptance",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "NI-3": {
        "value": "Fluid Intake",
        "children": {
          "1": {
            "value": "Inadequate fluid intake",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Excessive fluid intake",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "NI-4": {
        "value": "Bioactive Substances",
        "children": {
          "1": {
            "value": "Inadequate bioactive substance intake",
            "children": {
              "1": {
                "value": "Inadequate plant stanol ester intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Inadequate plant sterol ester intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Inadequate soy protein intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Inadequate psyllium intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Inadequate β-glucan intake",
                "children": {},
                "type": "checkbox", "checked": false
              }
            },
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Excessive bioactive substance intake",
            "children": {
              "1": {
                "value": "Excessive plant stanol ester intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Excessive plant sterol ester intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Excessive soy protein intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Excessive psyllium intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Excessive β-glucan intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Excessive food additive intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Excessive caffeine intake",
                "children": {},
                "type": "checkbox", "checked": false
              }
            },
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Excessive alcohol intake",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "NI-5": {
        "value": "Nutrient",
        "children": {
          "1": {
            "value": "Increased nutrient needs",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Inadequate protein-energy intake",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Decreased nutrient needs",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Imbalance of nutrients",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Fat and Cholesterol",
            "children": {
              "1": {
                "value": "Inadequate fat intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Excessive fat intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Intake of types of fats inconsistent with needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "6": {
            "value": "Protein",
            "children": {
              "1": {
                "value": "Inadequate protein intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Excessive protein intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Intake of types of proteins inconsistent with needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "7": {
            "value": "Amino Acid",
            "children": {
              "1": {
                "value": "Intake of types of amino acids inconsistent with needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "8": {
            "value": "Carbohydrate and Fiber",
            "children": {
              "1": {
                "value": "Inadequate carbohydrate intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Excessive carbohydrate intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Intake of types of carbohydrate inconsistent with needs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Inconsistent carbohydrate intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Inadequate fiber intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Excessive fiber intake",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "9": {
            "value": "Vitamin",
            "children": {
              "1": {
                "value": "Inadequate vitamin intake (specify)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Excessive vitamin intake (specify)",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "10": {
            "value": "Mineral",
            "children": {
              "1": {
                "value": "Inadequate mineral intake (specify)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Excessive mineral intake (specify)",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "11": {
            "value": "Multi-nutrient",
            "children": {
              "1": {
                "value": "Predicted inadequate nutrient intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Predicted excessive nutrient intake",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      }
    }
  },
  "NC": {
    "value": "CLINICAL",
    "children": {
      "NC-1": {
        "value": "Functional",
        "children": {
          "1": {
            "value": "Swallowing difficulty",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Biting/Chewing (masticatory) difficulty",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Breastfeeding difficulty",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Altered GI function",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Predicted breastfeeding difficulty",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "NC-2": {
        "value": "Biochemical",
        "children": {
          "1": {
            "value": "Impaired nutrient utilization",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Altered nutrition-related laboratory values",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Food–medication interaction",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Predicted food–medication interaction",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "NC-3": {
        "value": "Weight",
        "children": {
          "1": {
            "value": "Underweight",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Unintended weight loss",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Overweight/obesity",
            "children": {
              "1": {
                "value": "Overweight, adult or pediatric",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Obese, pediatric",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Obese, Class I",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Obese, Class II",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Obese, Class III",
                "children": {},
                "type": "checkbox", "checked": false
              }
            },
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Unintended weight gain",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Growth rate below expected",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "6": {
            "value": "Excessive growth rate",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "NC-4": {
        "value": "Malnutrition Disorders",
        "children": {
          "1": {
            "value": "Malnutrition",
            "children": {
              "1": {
                "value": "Starvation related malnutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Chronic disease or condition related malnutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Acute disease or injury related malnutrition",
                "children": {},
                "type": "checkbox", "checked": false
              }
            },
            "type": "checkbox", "checked": false
          }
        }
      }
    }
  },
  "NB": {
    "value": "BEHAVIORAL-ENVIRONMENTAL",
    "children": {
      "NB-1": {
        "value": "Knowledge and Beliefs",
        "children": {
          "1": {
            "value": "Food- and nutrition-related knowledge deficit",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Unsupported beliefs/attitudes about food- or nutrition-related topics (use with caution)",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Not ready for diet/lifestyle change",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Self-monitoring deficit",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Disordered eating pattern",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "6": {
            "value": "Limited adherence to nutrition-related recommendations",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "7": {
            "value": "Undesirable food choices",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "NB-2": {
        "value": "Physical Activity and Function",
        "children": {
          "1": {
            "value": "Physical inactivity",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Excessive physical activity",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Inability to manage self-care",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Impaired ability to prepare foods/meals",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Poor nutrition quality of life",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "6": {
            "value": "Self-feeding difficulty",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "NB-3": {
        "value": "Food Safety and Access",
        "children": {
          "1": {
            "value": "Intake of unsafe food",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Limited access to food",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Limited access to nutrition-related supplies",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Limited access to potable water",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      }
    }
  },
  "NO": {
    "value": "OTHER",
    "children": {
      "NO-1": {
        "value": "Other",
        "children": {
          "1": {
            "value": "No nutrition diagnosis at this time",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      }
    }
  },
  "ND": {
    "value": "FOOD AND/OR NUTRIENT DELIVERY",
    "children": {
      "ND-1": {
        "value": "Meals and Snacks",
        "children": {
          "1": {
            "value": "General/healthful diet",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Modify composition of meals/snacks",
            "children": {
              "1": {
                "value": "Texture-modified diet (1)",
                "children": {
                  "1": {
                    "value": "Easy to chew diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Mechanically altered diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Pureed diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Liquid consistency-thin liquids",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Liquid consistency-nectar thick liquids",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Liquid consistency-honey thick liquids",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Liquid consistency-spoon thick liquids",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Energy-modified diet (2)",
                "children": {
                  "1": {
                    "value": "Increased energy diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Decreased energy diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Protein-modified diet (3)",
                "children": {
                  "1": {
                    "value": "Consistent protein diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Increased protein diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Decreased protein diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Decreased casein diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Decreased gluten diet",
                    "children": {
                      "1": {
                        "value": "Gluten free diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Amino acid modified diet",
                    "children": {
                      "1": {
                        "value": "Arginine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased arginine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased arginine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Glutamine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased glutamine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased glutamine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "3": {
                        "value": "Histidine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased histidine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased histidine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "4": {
                        "value": "Increased homocysteine diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "5": {
                        "value": "Isoleucine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased isoleucine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased isoleucine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "6": {
                        "value": "Leucine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased leucine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased leucine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "7": {
                        "value": "Lysine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased lysine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased lysine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "8": {
                        "value": "Methionine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased methionine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased methionine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "9": {
                        "value": "Phenylalanine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased phenylalanine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased phenylalanine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "10": {
                        "value": "Threonine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased threonine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased threonine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "11": {
                        "value": "Tryptophan modified diet",
                        "children": {
                          "1": {
                            "value": "Increased tryptophan diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased tryptophan diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "12": {
                        "value": "Decreased tyramine diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "13": {
                        "value": "Tyrosine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased tyrosine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased tyrosine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "14": {
                        "value": "Valine modified diet",
                        "children": {
                          "1": {
                            "value": "Increased valine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased valine diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Carbohydrate-modified diet (4)",
                "children": {
                  "1": {
                    "value": "Consistent carbohydrate diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Increased carbohydrate diet",
                    "children": {
                      "1": {
                        "value": "Increased complex carbohydrate diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Increased simple carbohydrate diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Decreased carbohydrate diet",
                    "children": {
                      "1": {
                        "value": "Decreased complex carbohydrate diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased simple carbohydrate diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Galactose modified diet",
                    "children": {
                      "1": {
                        "value": "Increased galactose diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased galactose diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Lactose modified diet",
                    "children": {
                      "1": {
                        "value": "Increased lactose diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased lactose diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Fructose modified diet",
                    "children": {
                      "1": {
                        "value": "Increased fructose diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased fructose diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Fat-modified diet (5)",
                "children": {
                  "1": {
                    "value": "Increased fat diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Decreased fat diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Monounsaturated fat modified diet",
                    "children": {
                      "1": {
                        "value": "Increased monounsaturated fat diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased monounsaturated fat diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Polyunsaturated fat modified diet",
                    "children": {
                      "1": {
                        "value": "Increased polyunsaturated fat diet",
                        "children": {
                          "1": {
                            "value": "Increased linoleic acid diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased polyunsaturated fat diet",
                        "children": {
                          "1": {
                            "value": "Decreased linoleic acid diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Saturated fat modified diet",
                    "children": {
                      "1": {
                        "value": "Decreased saturated fat diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Trans fat modified diet",
                    "children": {
                      "1": {
                        "value": "Decreased trans fat modified diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Omega 3 fatty acid modified diet",
                    "children": {
                      "1": {
                        "value": "Increased omega 3 fatty acid diet",
                        "children": {
                          "1": {
                            "value": "Increased alphalinolenic acid diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Increased eicosapentaenoic acid diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "3": {
                            "value": "Increased docosahexaenoic acid",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased omega 3 fatty acid diet",
                        "children": {
                          "1": {
                            "value": "Decreased alphalinolenic acid diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "2": {
                            "value": "Decreased eicosapentaenoic acid diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          },
                          "3": {
                            "value": "Decreased docosahexaenoic acid diet",
                            "children": {},
                            "type": "checkbox", "checked": false
                          }
                        },
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Medium chain triglyceride modified diet",
                    "children": {
                      "1": {
                        "value": "Increased medium chain triglyceride diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased medium chain triglyceride diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Cholesterol-modified diet (6)",
                "children": {
                  "1": {
                    "value": "Decreased cholesterol diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Fiber-modified diet (7)",
                "children": {
                  "1": {
                    "value": "Increased fiber diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Decreased fiber diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Soluble fiber modified diet",
                    "children": {
                      "1": {
                        "value": "Increased soluble fiber diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased soluble fiber diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Insoluble fiber modified diet",
                    "children": {
                      "1": {
                        "value": "Increased insoluble fiber diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased insoluble fiber diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Fluid-modified diet (8)",
                "children": {
                  "1": {
                    "value": "Increased fluid diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Fluid restricted diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Clear liquid diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Full liquid diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Diets modified for specific foods or ingredients (9)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Vitamin-modified diet (10)",
                "children": {
                  "1": {
                    "value": "Vitamin A modified diet",
                    "children": {
                      "1": {
                        "value": "Increased vitamin A diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased vitamin A diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Vitamin C modified diet",
                    "children": {
                      "1": {
                        "value": "Increased vitamin C diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased vitamin C diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Vitamin D modified diet",
                    "children": {
                      "1": {
                        "value": "Increased vitamin D diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased vitamin D diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Vitamin E modified diet",
                    "children": {
                      "1": {
                        "value": "Increased vitamin E diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased vitamin E diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Vitamin K modified diet",
                    "children": {
                      "1": {
                        "value": "Increased vitamin K diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased vitamin K diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Thiamine modified diet",
                    "children": {
                      "1": {
                        "value": "Increased thiamine diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased thiamine diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Riboflavin modified diet",
                    "children": {
                      "1": {
                        "value": "Increased riboflavin diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased riboflavin diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Niacin modified diet",
                    "children": {
                      "1": {
                        "value": "Increased niacin diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased niacin diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Folic acid modified diet",
                    "children": {
                      "1": {
                        "value": "Increased folic acid diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased folic acid diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Vitamin B6 modified diet",
                    "children": {
                      "1": {
                        "value": "Increased vitamin B6 diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased vitamin B6 diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Vitamin B12 modified diet",
                    "children": {
                      "1": {
                        "value": "Increased vitamin B12 diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased vitamin B12 diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Pantothenic acid modified diet",
                    "children": {
                      "1": {
                        "value": "Increased pantothenic acid diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased pantothenic acid diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Biotin modified diet",
                    "children": {
                      "1": {
                        "value": "Increased biotin diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased biotin diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Mineral-modified diet (11)",
                "children": {
                  "1": {
                    "value": "Calcium modified diet",
                    "children": {
                      "1": {
                        "value": "Increased calcium diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased calcium diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Chloride modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Iron modified diet",
                    "children": {
                      "1": {
                        "value": "Increased iron diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased iron diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Magnesium modified diet",
                    "children": {
                      "1": {
                        "value": "Increased magnesium diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased magnesium diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Potassium modified diet",
                    "children": {
                      "1": {
                        "value": "Increased potassium diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased potassium diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Phosphorus modified diet",
                    "children": {
                      "1": {
                        "value": "Increased phosphorus diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased phosphorus diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Sodium modified diet",
                    "children": {
                      "1": {
                        "value": "Increased sodium diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased sodium diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Zinc modified diet",
                    "children": {
                      "1": {
                        "value": "Increased zinc diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased zinc diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Sulfur modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Fluoride modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Copper modified diet",
                    "children": {
                      "1": {
                        "value": "Increased copper diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased copper diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Iodine modified diet",
                    "children": {
                      "1": {
                        "value": "Increased iodine diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Decreased iodine diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Selenium modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Manganese modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Chromium modified diet",
                    "children": {
                      "1": {
                        "value": "Increased chromium diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Molybdenum modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Boron modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "18": {
                    "value": "Cobalt modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              }
            },
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Modify schedule of food/fluids",
            "children": {
              "1": {
                "value": "Modify schedule of intake to limit fasting",
                "children": {},
                "type": "checkbox", "checked": false
              }
            },
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Specific foods/beverages or groups",
            "children": {
              "1": {
                "value": "Fruit modified diet",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Vegetable modified diet",
                "children": {
                  "1": {
                    "value": "Starchy vegetable modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Bean and pea modified diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Grain modified diet",
                "children": {
                  "1": {
                    "value": "Diet modified for uncooked food starch",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Protein food modified diet",
                "children": {
                  "1": {
                    "value": "Diet with foods modified to be low in protein",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Diet modified for egg",
                    "children": {
                      "1": {
                        "value": "Raw egg free diet",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              }
            },
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Other",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "ND-2": {
        "value": "Enteral and Parenteral Nutrition",
        "children": {
          "1": {
            "value": "Enteral Nutrition",
            "children": {
              "1": {
                "value": "Modify composition of enteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Modify concentration of enteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Modify rate of enteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Modify volume of enteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Modify schedule of enteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Modify route of enteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Insert enteral feeding tube",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Enteral nutrition site care",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Feeding tube flush",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": "Parenteral Nutrition/IV Fluids",
            "children": {
              "1": {
                "value": "Modify composition of parenteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Modify concentration of parenteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Modify rate of parenteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Modify volume of parenteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Modify schedule of parenteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Modify route of parenteral nutrition",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Parenteral nutrition site care",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "IV fluid delivery",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "ND-3": {
        "value": "Nutrition Supplement Therapy",
        "children": {
          "1": {
            "value": "Medical Food Supplement Therapy",
            "children": {
              "1": {
                "value": "Commercial beverage",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Commercial food",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Modified beverage",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Modified food",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Purpose",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": "Vitamin and Mineral Supplement Therapy",
            "children": {
              "1": {
                "value": "Multivitamin/mineral supplement therapy",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Multi-trace element supplement therapy",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Vitamin supplement therapy",
                "children": {
                  "1": {
                    "value": "A",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "C",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "D",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "E",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "K",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Thiamin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Riboflavin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Niacin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Folate",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "B6",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "B12",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Pantothenic acid",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Biotin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "4": {
                "value": "Mineral supplement therapy",
                "children": {
                  "1": {
                    "value": "Calcium",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Chloride",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Iron",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Magnesium",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Potassium",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Phosphorus",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Sodium",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Zinc",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Sulfate",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Fluoride",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Copper",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Iodine",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Selenium",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Manganese",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Chromium",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Molybdenum",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Boron",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "18": {
                    "value": "Cobalt",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              }
            }
          },
          "3": {
            "value": "Bioactive Substance Management",
            "children": {
              "1": {
                "value": "Plant stanol esters",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Plant sterol esters",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Soy protein",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Psyllium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Glucan",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Food additives (specify)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Alcohol",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Caffeine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Other (specify)",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "ND-4": {
        "value": "Feeding Assistance",
        "children": {
          "1": {
            "value": "Adaptive eating device",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Feeding position",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Meal set-up",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Mouth care",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Menu selection assistance",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "6": {
            "value": "Other",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "ND-5": {
        "value": "Manage Feeding Environment",
        "children": {
          "1": {
            "value": "Lighting",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Odors",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Distractions",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Table height",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Table service",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "6": {
            "value": "Room temperature",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "7": {
            "value": "Meal service",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "8": {
            "value": "Meal location",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "9": {
            "value": "Other",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "ND-6": {
        "value": "Nutrition-Related Medication Management",
        "children": {
          "1": {
            "value": "Prescription medication",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "OTC medication",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Complementary/ alternative medicine",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      }
    }
  },
  "E": {
    "value": "NUTRITION EDUCATION",
    "children": {
      "E-1": {
        "value": "Nutrition Education–Content",
        "children": {
          "1": {
            "value": "Purpose of the nutrition education",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Priority modifications",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Survival information",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Nutrition relationship to health/disease",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Recommended modifications",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "6": {
            "value": "Other or related topics",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "7": {
            "value": "Other",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "8": {
            "value": "Physical activity guidance",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "E-2": {
        "value": "Nutrition Education–Application",
        "children": {
          "1": {
            "value": "Result interpretation",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Skill development",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Other",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      }
    }
  },
  "C": {
    "value": "NUTRITION COUNSELING",
    "children": {
      "C-1": {
        "value": "Theoretical Basis/Approach",
        "children": {
          "1": {
            "value": "Cognitive-behavioral theory",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Health belief model",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Social learning theory",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Transtheoretical model/stages of change",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Other",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "C-2": {
        "value": "Strategies",
        "children": {
          "1": {
            "value": "Motivational interviewing",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Goal setting",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Self-monitoring",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Problem solving",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Social support",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "6": {
            "value": "Stress management",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "7": {
            "value": "Stimulus control",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "8": {
            "value": "Cognitive restructuring",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "9": {
            "value": "Relapse prevention",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "10": {
            "value": "Rewards/contingency management",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "11": {
            "value": "Other",
            "children": {
              "": {
                "value": "",
                "children": {},
                "type": "input",
                "checked": false,
              }
            },
            "type": "checkbox", "checked": false
          }
        }
      }
    }
  },
  "RC": {
    "value": "COORDINATION OF NUTRITION CARE BY A NUTRITION PROFESSIONAL",
    "children": {
      "RC-1": {
        "value": "Collaboration and Referral of Nutrition Care",
        "children": {
          "1": {
            "value": "Team meeting",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Referral to RDN with different expertise",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Collaboration with other nutrition professionals",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "4": {
            "value": "Collaboration with other providers",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "5": {
            "value": "Referral to other providers",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "6": {
            "value": "Referral to community agencies/programs",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      },
      "RC-2": {
        "value": "Discharge and Transfer of Nutrition Care to New Setting or Provider",
        "children": {
          "1": {
            "value": "Discharge and transfer to other providers",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "2": {
            "value": "Discharge and transfer to community agencies/programs",
            "children": {},
            "type": "checkbox", "checked": false
          },
          "3": {
            "value": "Discharge and transfer to another nutrition professional",
            "children": {},
            "type": "checkbox", "checked": false
          }
        }
      }
    }
  },
  "FH": {
    "value": null,
    "children": {
      "FH-1": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Total energy intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              }
            }
          },
          "2": {
            "value": null,
            "children": {
              "1": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Oral fluids",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Food-derived fluids",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Liquid meal replacement or supplement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "2": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Amount of food",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Types of food/meals",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Meal/snack pattern",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Diet quality index",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Food variety",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "3": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Breastmilk intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Infant formula intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              }
            }
          },
          "3": {
            "value": null,
            "children": {
              "1": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Enteral nutrition formula/solution",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Feeding tube flush",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "2": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Parenteral nutrition formula/solution",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "IV fluids",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              }
            }
          },
          "4": {
            "value": null,
            "children": {
              "1": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Drink size/volume",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Frequency",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Pattern of alcohol consumption",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "2": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Plant stanol ester intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Plant sterol ester intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Soy protein intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Psyllium intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "β-glucan intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Food additive intake (specify)",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Other (specify)",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "3": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Total caffeine intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              }
            }
          },
          "5": {
            "value": null,
            "children": {
              "1": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Total fat intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Saturated fat intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Trans fatty acid intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Polyunsaturated fat intake",
                    "children": {
                      "1": {
                        "value": "Linoleic acid intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Monounsaturated fat intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Omega-3 fatty acid intake",
                    "children": {
                      "1": {
                        "value": "Alphalinolenic acid intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Eicosapentaenoic acid intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "3": {
                        "value": "Docosahexaenoic acid intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Essential fatty acid intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Medium chain triglyceride intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "2": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Dietary cholesterol intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "3": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Total protein intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "High biological value protein intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Casein intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Whey intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Gluten intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Natural protein intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "4": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Total amino acid intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Essential amino acid intake",
                    "children": {
                      "1": {
                        "value": "Histidine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Methionine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "3": {
                        "value": "Isoleucine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "4": {
                        "value": "Leucine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "5": {
                        "value": "Lysine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "6": {
                        "value": "Threonine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "7": {
                        "value": "Tryptophan intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "8": {
                        "value": "Phenylalanine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "9": {
                        "value": "Valine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Nonessential amino acid intake",
                    "children": {
                      "1": {
                        "value": "Arginine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "2": {
                        "value": "Glutamine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "3": {
                        "value": "Homocysteine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "4": {
                        "value": "Tyramine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      },
                      "5": {
                        "value": "Tyrosine intake",
                        "children": {},
                        "type": "checkbox", "checked": false
                      }
                    },
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "5": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Total carbohydrate intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Complex carbohydrate intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Simple sugar carbohydrate intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Galactose intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Lactose intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Fructose intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Estimated total daily glycemic index value",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Estimated total daily glycemic load",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Total carbohydrate from diet",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Total carbohydrate from enteral nutrition",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Total carbohydrate from parenteral nutrition",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Total carbohydrate from intravenous fluids",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Insulin-to-carbohydrate ratio",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "6": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Total fiber intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Soluble fiber intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Insoluble fiber intake",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              }
            }
          }
        }
      },
      "FH-2": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": null,
                "children": {
                  "1": {
                    "value": "General, healthful diet order",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Modified diet order",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Enteral nutrition order",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Parenteral nutrition order",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "2": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Previously prescribed diets",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Previous diet/nutrition education/counseling",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Self-selected diet/s followed",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Dieting attempts",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Food allergies",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Food intolerance",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "3": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Location",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Atmosphere",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Caregiver/companion",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Appropriate breastfeeding accommodations/facility",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Eats alone",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "4": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Enteral access",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Parenteral access",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Body position, EN",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "5": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Fasting pattern in one calendar day, reported",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Fasting pattern in one calendar week, reported",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Fasting pattern in one calendar month, reported",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Fasting pattern in one calendar year, reported",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Fasting tolerance, reported",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              }
            }
          }
        }
      },
      "FH-3": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Prescription medication use",
                "children": {
                  "1": {
                    "value": "Insulin sensitivity factor",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "OTC medication use",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Misuse of medication",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": null,
            "children": {
              "1": {
                "value": "Nutrition-related complementary/alternative medicine use",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "FH-4": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Area(s) and level of knowledge/skill",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Diagnosis specific or global nutrition-related knowledge score",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": null,
            "children": {
              "1": {
                "value": "Conflict with personal/family value system",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Distorted body image",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "End-of-life decisions",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Motivation",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Preoccupation with food/nutrients",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Preoccupation with weight",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Readiness to change nutrition-related behaviors",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Self-efficacy",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Self-talk/cognitions",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Unrealistic nutrition-related goals",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Unscientific beliefs/attitudes",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Food preferences",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Emotions",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "FH-5": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Self-reported adherence score",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Nutrition visit attendance",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Ability to recall nutrition goals",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Self-monitoring at agreed upon rate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Self-management as agreed upon",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": null,
            "children": {
              "1": {
                "value": "Avoidance",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Restrictive eating",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Cause of avoidance behavior",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "3": {
            "value": null,
            "children": {
              "1": {
                "value": "Binge eating behavior",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Purging behavior",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "4": {
            "value": null,
            "children": {
              "1": {
                "value": "Meal duration",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Percent of meal time spent eating",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Preference to drink rather than eat",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Refusal to eat/chew",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Spitting food out",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Rumination",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Patient/client/caregiver fatigue during feeding process resulting in inadequate intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Willingness to try new foods",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Limited number of accepted foods",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Rigid sensory preferences",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "5": {
            "value": null,
            "children": {
              "1": {
                "value": "Ability to build and utilize social network",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "FH-6": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Eligibility for government programs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Participation in government programs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Eligibility for community programs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Participation in community programs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": null,
            "children": {
              "1": {
                "value": "Availability of shopping facilities",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Procurement of safe food",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Appropriate meal preparation facilities",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Availability of safe food storage",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Appropriate storage technique",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Identification of safe food",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "3": {
            "value": null,
            "children": {
              "1": {
                "value": "Availability of potable water",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Appropriate water decontamination",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "4": {
            "value": null,
            "children": {
              "1": {
                "value": "Access to food and nutrition-related supplies",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Access to assistive eating devices",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Access to assistive food preparation devices",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "FH-7": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Initiation of breastfeeding",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Duration of breastfeeding",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Exclusive breastfeeding",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Breastfeeding problems",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": null,
            "children": {
              "1": {
                "value": "Physical ability to complete tasks for meal preparation",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Physical ability to self-feed",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Ability to position self in relation to plate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Receives assistance with intake",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Ability to use adaptive eating devices",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Cognitive ability to complete tasks for meal preparation",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Remembers to eat",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Recalls eating",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Mini Mental State Examination Score",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Nutrition-related activities of daily living (ADL) score",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Nutrition-related instrumental activities of daily living (IADL) score",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "3": {
            "value": null,
            "children": {
              "1": {
                "value": "Physical activity history",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Consistency",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Frequency",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Duration",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Intensity",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Type of physical activity",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Strength",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "TV/screen time",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Other sedentary activity time",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Involuntary physical movement",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Non exercise activity thermogenesis",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "4": {
            "value": null,
            "children": {
              "1": {
                "value": "Neighborhood safety",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Walkability of neighborhood",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Proximity to parks/green space",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Access to physical activity facilities/programs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "FH-8": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Nutrition quality of life responses",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      }
    }
  },
  "AD": {
    "value": null,
    "children": {
      "AD-1": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Height",
                "children": {
                  "1": {
                    "value": "Measured height",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Measured length",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Birth length",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Pre-amputation measured height",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Pre-amputation estimated height",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Estimated height",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Stated height",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Measured peak adult height",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Stated peak adult height",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Knee height",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Tibia length",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Arm span",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Arm demispan",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Arm halfspan",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Height measurement device",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Weight",
                "children": {
                  "1": {
                    "value": "Measured weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Stated weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Stated peak weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Measured peak weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Usual stated body weight (UBW)",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "UBW percentage",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Birth weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Stated pre-pregnancy weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Dosing weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Estimated dry weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Pre-amputation measured weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Pre-amputation estimated weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Post-amputation measured weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Post-amputation estimated weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Pre-dialysis weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Post-dialysis weight",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Frame",
                "children": {
                  "1": {
                    "value": "Frame size",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Wrist circumference",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Weight change",
                "children": {
                  "1": {
                    "value": "Weight gain",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Weight loss",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Weight change percentage",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Measured interdialytic weight gain",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Measured interdialytic weight loss",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Measured gestational weight gain",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Measured gestational weight loss",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Weight change intent",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Body mass",
                "children": {
                  "1": {
                    "value": "Body mass index",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Body mass index prime ratio",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Growth pattern indices",
                "children": {
                  "1": {
                    "value": "BMI-for-age percentile",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "BMI-for-age z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Head circumference",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Birth head circumference",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Head circumference-for-age percentile",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Head circumference-for-age z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Length-for-age percentile",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Length-for-age z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Stature-for-age percentile",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Stature-for-age z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Weight-for-length percentile",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Weight-for-length z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Weight-for-age percentile",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Weight-for-age z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Weight-for-stature percentile",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Weight-for-stature z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Mid parental height comparator",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Body compartment estimates",
                "children": {
                  "1": {
                    "value": "Body fat percentage",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Body fat percentage technique",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Body surface area",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Calculated body surface area",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Bone age",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Bone mineral density t score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Bone mineral density z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Bone mineral density technique",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Mid arm muscle circumference",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Mid arm muscle circumference percentile",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Triceps skinfold thickness",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Triceps skinfold percentile",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Triceps skinfold z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Waist circumference",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Waist circumference narrowest point",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Waist circumference iliac crest",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Hip circumference",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "18": {
                    "value": "Waist to hip ratio",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "19": {
                    "value": "Mid upper arm circumference",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "20": {
                    "value": "Mid upper arm circumference left arm",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "21": {
                    "value": "Mid upper arm circumference z score",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                },
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      }
    }
  },
  "BD": {
    "value": null,
    "children": {
      "BD-1": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Arterial pH",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Arterial bicarbonate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Partial pressure of carbon dioxide in arterial blood, PaCO2",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Partial pressure of oxygen in arterial blood, PaO2",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Venous pH",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Venous bicarbonate",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": null,
            "children": {
              "1": {
                "value": "BUN",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Creatinine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "BUN:creatinine ratio",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Glomerular filtration rate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Sodium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Chloride",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Potassium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Magnesium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Calcium, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Calcium, ionized",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Phosphorus",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Serum osmolality",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Parathyroid hormone",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "3": {
            "value": null,
            "children": {
              "1": {
                "value": "Triene:Tetraene ratio",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "4": {
            "value": null,
            "children": {
              "1": {
                "value": "Alkaline phosphatase",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Alanine aminotransferase, ALT",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Aspartate aminotransferase, AST",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Gamma glutamyl transferase, GGT",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Gastric residual volume",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Bilirubin, total",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Ammonia, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Toxicology report, including alcohol",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Prothrombin time, PT",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Partial thromboplastin time, PTT",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "INR (ratio)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Amylase",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Lipase",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "14": {
                "value": "Fecal fat, 24 hour",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "15": {
                "value": "Fecal fat, 72 hour",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "16": {
                "value": "Fecal fat, qualitative",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "17": {
                "value": "Fecal calprotectin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "18": {
                "value": "Fecal lactoferrin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "19": {
                "value": "Pancreatic elastase",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "20": {
                "value": "5'nucleotidase",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "21": {
                "value": "D-xylose",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "22": {
                "value": "Lactulose hydrogen breath test",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "23": {
                "value": "Lactose hydrogen breath test",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "24": {
                "value": "Fructose hydrogen breath test",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "25": {
                "value": "Glucose hydrogen breath test",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "26": {
                "value": "Urea hydrogen breath test",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "27": {
                "value": "Intestinal biopsy",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "28": {
                "value": "Stool culture",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "29": {
                "value": "Gastric emptying time",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "30": {
                "value": "Small bowel transit time",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "31": {
                "value": "Abdominal X-ray",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "32": {
                "value": "Abdominal CT",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "33": {
                "value": "Abdominal ultrasound",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "34": {
                "value": "Endoscopic ultrasound",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "35": {
                "value": "Pelvic CT",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "36": {
                "value": "Modified barium swallow",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "37": {
                "value": "Barium swallow",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "38": {
                "value": "Esophagogastroduodenoscopy",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "39": {
                "value": "ERCP",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "40": {
                "value": "Capsule endoscopy",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "41": {
                "value": "Esophageal manometry",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "42": {
                "value": "Esophageal pH test",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "43": {
                "value": "Gastroesophageal reflux monitoring",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "44": {
                "value": "Gastrointestinal sphincter monitoring",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "45": {
                "value": "Urate",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "5": {
            "value": null,
            "children": {
              "1": {
                "value": "Glucose, fasting",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Glucose, casual",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "HgbA1c",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Preprandial capillary plasma glucose",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Peak postprandial capillary plasma glucose",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Glucose tolerance test",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Cortisol level",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "IGF-binding protein",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Thyroid stimulating hormone",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Thyroxine test",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Triiodothyronine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Adrenocorticotropic hormone",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Follicle stimulating hormone",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "14": {
                "value": "Growth hormone",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "15": {
                "value": "Luteinizing hormone",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "6": {
            "value": null,
            "children": {
              "1": {
                "value": "C-reactive protein",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "7": {
            "value": null,
            "children": {
              "1": {
                "value": "Cholesterol, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Cholesterol, HDL",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Cholesterol, LDL",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Cholesterol, non-HDL",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Total cholesterol:HDL cholesterol",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "LDL:HDL",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Triglycerides, serum",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "8": {
            "value": null,
            "children": {
              "1": {
                "value": "Resting metabolic rate, measured",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Respiratory quotient, measured",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "9": {
            "value": null,
            "children": {
              "1": {
                "value": "Copper, serum or plasma",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Iodine, urinary excretion",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Zinc, serum or plasma",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Boron, serum or plasma",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Chromium, serum or urinary",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Fluoride, plasma",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Manganese, urinary, blood, plasma",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Molybdenum, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Selenium, serum or urinary",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "10": {
            "value": null,
            "children": {
              "1": {
                "value": "Hemoglobin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Hematocrit",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Mean corpuscular volume",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Red blood cell folate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Red cell distribution width",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "B12, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Methylmalonic acid, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Folate, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Homocysteine, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Ferritin, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Iron, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Total iron-binding capacity",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Transferrin saturation",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "11": {
            "value": null,
            "children": {
              "1": {
                "value": "Albumin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Prealbumin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Transferrin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Phenylalanine, plasma",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Tyrosine, plasma",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Amino acid panel",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Phenylalanine, dried blood spot",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Tyrosine, dried blood spot",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Phenylalanine:tyrosine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Hydroxyproline",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Threonine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Serine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Asparagine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "14": {
                "value": "Glutamate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "15": {
                "value": "Glutamine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "16": {
                "value": "Proline",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "17": {
                "value": "Glycine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "18": {
                "value": "Alanine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "19": {
                "value": "Citrulline",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "20": {
                "value": "Valine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "21": {
                "value": "Cysteine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "22": {
                "value": "Methionine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "23": {
                "value": "Isoleucine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "24": {
                "value": "Leucine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "25": {
                "value": "Ornithine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "26": {
                "value": "Lysine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "27": {
                "value": "Histidine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "28": {
                "value": "Arginine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "29": {
                "value": "Lysine:arginine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "30": {
                "value": "Tryptophan",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "31": {
                "value": "Plasma organic acid panel",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "32": {
                "value": "Organic acids/creatinine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "33": {
                "value": "3-hydroxybutyrate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "34": {
                "value": "3-hydroxyisovalerate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "35": {
                "value": "Acetoacetate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "36": {
                "value": "Ethylmalonate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "37": {
                "value": "Succinate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "38": {
                "value": "Fumarate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "39": {
                "value": "Glutarate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "40": {
                "value": "3-methylglutarate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "41": {
                "value": "Adipate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "42": {
                "value": "2-hydroxyglutarate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "43": {
                "value": "3-hydroxyphenylacetate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "44": {
                "value": "2-ketoglutarate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "45": {
                "value": "Citrate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "46": {
                "value": "Propionate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "47": {
                "value": "Methylcitrate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "48": {
                "value": "3-hydroxy propionate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "49": {
                "value": "3-hydroxy propionate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "50": {
                "value": "Creatine kinase",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "51": {
                "value": "Troponin I. cardiac",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "52": {
                "value": "Troponin T. cardiac",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "53": {
                "value": "B-type natriuretic peptide",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "54": {
                "value": "Succinylacetone",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "55": {
                "value": "Total serum immunoglobulin A",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "56": {
                "value": "Tissue transglutaminase antibodies (IgA)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "57": {
                "value": "Tissue transglutaminase antibodies (IgG)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "58": {
                "value": "Deamidated gliadin peptide antibodies (IgG)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "59": {
                "value": "Endomysial antibodies",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "60": {
                "value": "Carbohydrate-deficient transferrin",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "12": {
            "value": null,
            "children": {
              "1": {
                "value": "Urine color",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Urine osmolality",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Urine specific gravity",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Urine volume",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Urine calcium, 24 hour",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Urine d-xylose",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Urine glucose",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Urine ketones",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Urine sodium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Urine microalbumin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Urine protein, random",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Urine protein, 24 hour",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Urine uric acid, random",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "14": {
                "value": "Urine uric acid, 24 hour",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "15": {
                "value": "Urine organic acid panel",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "16": {
                "value": "Urine glutarate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "17": {
                "value": "Urine methylmalonate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "18": {
                "value": "Urine acylglycines/creatinine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "19": {
                "value": "Urine argininosuccinate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "20": {
                "value": "Urine succinylacetone/creatinine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "21": {
                "value": "Urine orotate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "22": {
                "value": "Urine orotate/creatinine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "23": {
                "value": "Urine 2-hydroxyisovalerate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "24": {
                "value": "Urine 2-oxoisovalerate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "25": {
                "value": "Urine galactitol",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "26": {
                "value": "Urine reducing substances",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "27": {
                "value": "Urine porphyrins",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "13": {
            "value": null,
            "children": {
              "1": {
                "value": "Vitamin A, serum or plasma retinol",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Vitamin C, plasma or serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Vitamin D, 25-hydroxy",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Vitamin E, plasma alpha-tocopherol",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Thiamin, activity coefficient for erythrocyte transketolase activity",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Riboflavin, activity coefficient for erythrocyte glutathione reductase activity",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Niacin, urinary N’methyl-nicotinamide concentration",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Vitamin B6, plasma or serum pyridoxal 5’phosphate concentration",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Pantothenic acid, urinary pantothenate excretion, plasma",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Biotin, urinary 3-hydroxyisovaleric acid excretion",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Biotin, lymphocyte propionyl-CoA carboxylase in pregnancy, serum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Biotinidase",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Protein induced by vitamin K absence or antagonist II",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "14": {
            "value": null,
            "children": {
              "1": {
                "value": "Galactose-1-phosphate in red blood cell",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Galactose-1-phosphate uridyl transferase",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Fructose",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Lactate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Pyruvate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Lactate:pyruvate",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "15": {
            "value": null,
            "children": {
              "1": {
                "value": "Acylcarnitine panel",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Acylcarnitine, plasma",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Free carnitine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Total carnitine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Free carnitine:total carnitine C8-C18",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Fatty acid panel mitochondrial C8-C18",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Fatty acid panel essential C12-C22",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Fatty acid panel peroxisomal C22-C26",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "MCAD enzyme assay in fibroblasts or other tissues",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Fatty acid β-oxidation in fibroblasts",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      }
    }
  },
  "PD": {
    "value": null,
    "children": {
      "PD-1": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Asthenia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Buffalo hump",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Cachexia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Cushingoid appearance",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Ectomorph",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Endomorph",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Lethargic",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Mesomorph",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Neglect of personal hygiene",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Obese",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Short stature for age",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Tall stature",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "2": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Atrophy of orbital fat",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Excess subcutaneous fat",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Loss of subcutaneous fat",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Central adiposity",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Loss of subcutaneous triceps fat",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Loss of subcutaneous biceps fat",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Loss of subcutaneous fat overlying the ribs",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "3": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Bow legs",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Frontal bossing",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Harrison’s sulcus",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Rachitic rosary",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Rickets",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Scoliosis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Acromion abnormal prominence",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Bone widening at ends",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Clavicle abnormal prominence",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Rib abnormal prominence",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Scapula abnormal prominence",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Spine abnormal prominence",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Iliac crest abnormal prominence",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Patella abnormal prominence",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "4": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Absent breath sounds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Bradycardia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Bradypnea",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Decreased breath sounds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Dyspnea",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Increased breath sounds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Normal breath sounds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Tachypnea",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Tachycardia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Respiratory crackles",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "5": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Abdominal bloating",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Abdominal cramping",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Abdominal distension",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Abdominal pain",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Absence of bowel sounds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Anorexia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Ascites",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Bulky stool",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Constipation",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Decrease in appetite",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Diarrhea",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Early satiety",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Epigastric pain",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Excessive appetite",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Excessive belching",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Excessive flatus",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Fatty stool",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "18": {
                    "value": "Heartburn",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "19": {
                    "value": "Hyperactive bowel sounds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "20": {
                    "value": "Hypoactive bowel sounds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "21": {
                    "value": "Increased appetite",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "22": {
                    "value": "Liquid stool",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "23": {
                    "value": "Loose stool",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "24": {
                    "value": "Nausea",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "25": {
                    "value": "Normal bowel sounds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "26": {
                    "value": "Retching",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "27": {
                    "value": "Vomiting",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "28": {
                    "value": "Gastrointestinal drainage volume",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "29": {
                    "value": "Gastric drainage volume",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "30": {
                    "value": "Bile duct drainage volume",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "31": {
                    "value": "Pancreatic drainage volume",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "32": {
                    "value": "Chylous drainage volume",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "33": {
                    "value": "Wound drainage volume",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "34": {
                    "value": "Intestinal fistula output volume",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "6": {
                "value": null,
                "children": {
                  "1": {
                    "value": "+1 pitting edema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "+2 pitting edema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "+2 pitting edema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "+4 pitting edema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Anasarca",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Ankle edema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Edema of calf",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Edema of eyelid",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Edema of foot",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Edema of hand",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Edema of scrotum",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Edema of thigh",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Edema of vulva",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Mucosal edema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Sacral edema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "7": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Amputated foot",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Amputated hand",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Amputated leg",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Athetoid movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Decreased range of ankle movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Decreased range of cervical spine movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Decreased range of elbow movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Decreased range of finger movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Decreased range of foot movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Decreased range of hip movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Decreased range of knee movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Decreased range of lumbar spine movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Decreased range of shoulder movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Decreased range of subtalar movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Decreased range of thumb movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Decreased range of toe movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Decreased range of thoracic spine movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "18": {
                    "value": "Decreased range of wrist movement",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "19": {
                    "value": "Hypertonia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "20": {
                    "value": "Hypotonia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "21": {
                    "value": "Joint arthralgia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "22": {
                    "value": "Lower limb spasticity",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "23": {
                    "value": "Peripheral cyanosis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "24": {
                    "value": "Spasticity",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "25": {
                    "value": "Tetany",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "26": {
                    "value": "Upper limb spasticity",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "8": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Abnormal vision",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Angular blepharitis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Biotot’s spots",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Circles under eyes",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Corneal arcus",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Conjunctival discoloration",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Conjunctival hemorrhage",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Conjunctival keratinization",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Excessive tear production",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Keratomalacia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Jaundiced sclera",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Night blindness",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Ophthalmoplegia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Sunken eyes",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Xerophthalmia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Xanthelasma",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Pale conjunctiva",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "9": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Amenorrhea",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Anuria",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Delay in sexual development and/or puberty",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Menorrhagia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Oliguria",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Polyuria",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "10": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Abnormal keratinization of hair follicle",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Alopecia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Brittle hair",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Corkscrew hairs",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Dry hair",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Fine hair",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Follicular hyperkeratosis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Hair changes due to malnutrition",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Hair lacks luster",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Hypertrichosis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Increased loss of hair",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Nutritional hair color change",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "White hair",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Easily pluckable hair",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Lanugo hair formation",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "11": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Altered olfactory sense",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Anosmia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Bulging fontanelle",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Epistaxis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Headache",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Hyposmia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Macrocephaly",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Microcephaly",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Nasal mucosa dry",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Sunken fontanelle",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "12": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Beau’s lines",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Clubbing of nails",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Flaking of nails",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Koilonychia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Leukonychia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Longitudinal grooving of nails",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Muehrcke’s lines",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Nail changes",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Palmar erythema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Ridged nails",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Splits in nails",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Thin nails",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Trachyonychia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Splinter hemorrhage under nails",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "White flecks in nails",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Blue nail beds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Pale nail beds",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "18": {
                    "value": "Russell’s sign",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "13": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Ageusia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Angular stomatitis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Aphthous ulcer of mouth",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Aptyalism",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Blue lips",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Blue line on gingiva",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Candidiasis of the mouth",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Cheilosis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Cheilitis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Cleft palate",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Cracked lips",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Drooling",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Dry mucus membranes",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Dysgeusia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Excessive salivation",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Excessive thirst",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Gingival hypertrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "18": {
                    "value": "Gingivitis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "19": {
                    "value": "Halitosis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "20": {
                    "value": "Hemorrhagic gingivitis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "21": {
                    "value": "Hypogeusia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "22": {
                    "value": "Ketotic breath",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "23": {
                    "value": "Micrognathia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "24": {
                    "value": "Swollen gums",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "25": {
                    "value": "Oral candidiasis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "26": {
                    "value": "Oral lesion",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "27": {
                    "value": "Parotid swelling",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "28": {
                    "value": "Poor oral hygiene",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "29": {
                    "value": "Retains food in mouth",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "30": {
                    "value": "Stomatitis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "31": {
                    "value": "Uremic breath",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "32": {
                    "value": "Pale gums",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "14": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Muscle atrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Muscle contracture",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Muscle cramp",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Muscle pain",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Muscle weakness",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Quadriceps muscle atropjhy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Deltoid muscle atrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Gastrocnemius muscle atrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Gluteal muscle atrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Interosseous hand muscle atrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Pectoral muscle atrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Temporalis muscle atrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Trapezius muscle atrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Latissiumus dorsi muscle atrophy",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "15": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Goiter",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "16": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Abnormal gait",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Absent reflex",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Asterixis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Ataxia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Clouded consciousness",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Cranial nerve finding",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Decreased vibratory sense",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Delirious",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Dementia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Depressed mood",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Disoriented",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Dizziness",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Feels cold",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Flat affect",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Hyperreflexia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Hyporeflexia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Inappropriate affect",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "18": {
                    "value": "Many seizures a day",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "19": {
                    "value": "Numbness of foot",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "20": {
                    "value": "Numbness of hand",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "21": {
                    "value": "Peripheral nerve disease",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "22": {
                    "value": "Tremor of outstretched hand",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "23": {
                    "value": "Tingling of foot",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "24": {
                    "value": "Tingling of hand",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "17": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Acanthosis nigricans",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Calcinosis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Carotenemia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Cutaneous xanthoma",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Decreased skin turgor",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Dermatitis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Diaper rash",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Dry skin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Ecchymosis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Erythema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Eczema",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Flushing",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Hirsutism",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Hyperpigmentation of skin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Impaired skin integrity",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Jaundice",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Keratinization of skin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "18": {
                    "value": "Pale complexion",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "19": {
                    "value": "Peeling skin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "20": {
                    "value": "Petechiae",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "21": {
                    "value": "Impaired wound healing",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "22": {
                    "value": "Pressure injury of ankles",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "23": {
                    "value": "Pressure injury of back",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "24": {
                    "value": "Pressure injury of breast",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "25": {
                    "value": "Pressure injury of buttock",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "26": {
                    "value": "Pressure injury of dorsum of foot",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "27": {
                    "value": "Pressure injury of elbow",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "28": {
                    "value": "Pressure injury of head",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "29": {
                    "value": "Pressure injury of heel",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "30": {
                    "value": "Pressure injury of hip",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "31": {
                    "value": "Pressure injury of knee",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "32": {
                    "value": "Pressure injury of natal cleft",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "33": {
                    "value": "Pressure injury of shoulder",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "34": {
                    "value": "Pressure injury stage 1",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "35": {
                    "value": "Pressure injury stage 2",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "36": {
                    "value": "Pressure injury stage 3",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "37": {
                    "value": "Pressure injury stage 4",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "38": {
                    "value": "Pruritus of the skin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "39": {
                    "value": "Psoriasis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "40": {
                    "value": "Scaling skin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "41": {
                    "value": "Seborrheic dermatitis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "42": {
                    "value": "Skin rash",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "43": {
                    "value": "Stasis ulcer",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "44": {
                    "value": "Yellow skin",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "45": {
                    "value": "Perifollicular hemorrhages",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "46": {
                    "value": "Pressure injury of the coccyx",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "47": {
                    "value": "Pressure injury of the sacrum",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "48": {
                    "value": "Vesiculobullous rash",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "18": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Baby bottle tooth decay",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Broken denture",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Broken teeth",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Dental caries",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Dental fluorosis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Dental plaque",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Denture loose",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Denture lost",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Denture present",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Edentulous",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Erosion of teeth",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Impaired dentition",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Ill fitting denture",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Mottling of enamel",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Partially edentulous mandible",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Partially edentulous maxilla",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "17": {
                    "value": "Rampant dental caries",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "19": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Choking during swallowing",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Cough",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Dysphagia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Esophageal lesion",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Food sticks on swallowing",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Gagging",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Hoarse voice",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Hypoactive gag reflex",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Odynophagia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Swallow impairment",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Suck, swallow, breath incoordination",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "20": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Atrophy of tongue papillae",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Beefy red tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Difficulty moving tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Dry tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Glossitis",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Glossodynia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Hypertrophy of tongue papillae",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "8": {
                    "value": "Lesion of the tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "9": {
                    "value": "Strawberry tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "10": {
                    "value": "Macroglossia",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "11": {
                    "value": "Short frenulum of tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "12": {
                    "value": "Split frenulum of tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "13": {
                    "value": "Blue tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "14": {
                    "value": "Cracked tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "15": {
                    "value": "Magenta tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "16": {
                    "value": "Pale tongue",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              },
              "21": {
                "value": null,
                "children": {
                  "1": {
                    "value": "Blood pressure",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "2": {
                    "value": "Heart rate",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "3": {
                    "value": "Jugular venous pressure",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "4": {
                    "value": "Mean arterial pressure",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "5": {
                    "value": "Pulse rate",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "6": {
                    "value": "Respiratory rate",
                    "children": {},
                    "type": "checkbox", "checked": false
                  },
                  "7": {
                    "value": "Temperature",
                    "children": {},
                    "type": "checkbox", "checked": false
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  "CS": {
    "value": null,
    "children": {
      "CS-1": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Total energy estimated needs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Method for estimating needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "CS-2": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Total fat estimated needs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Type of fat needed",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Method for estimating needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": null,
            "children": {
              "1": {
                "value": "Total protein estimated needs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Type of protein needed",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Method for estimating needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "3": {
            "value": null,
            "children": {
              "1": {
                "value": "Total carbohydrate estimated needs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Type of carbohydrate needed",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Method for estimating needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "4": {
            "value": null,
            "children": {
              "1": {
                "value": "Total fiber estimated needs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Type of fiber needed",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Method for estimating needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "CS-3": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Total fluid estimated needs",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Method for estimating needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "CS-4": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "A",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "C",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "D",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "E",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "K",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Thiamin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Riboflavin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Niacin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Folate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "B6",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "B12",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Pantothenic acid",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Biotin",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "14": {
                "value": "Method for estimating needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          },
          "2": {
            "value": null,
            "children": {
              "1": {
                "value": "Calcium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Chloride",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Iron",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Magnesium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Potassium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Phosphorus",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Sodium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Zinc",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "9": {
                "value": "Sulfate",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "10": {
                "value": "Fluoride",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "11": {
                "value": "Copper",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "12": {
                "value": "Iodine",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "13": {
                "value": "Selenium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "14": {
                "value": "Manganese",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "15": {
                "value": "Chromium",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "16": {
                "value": "Molybdenum",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "17": {
                "value": "Boron",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "18": {
                "value": "Cobalt",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "19": {
                "value": "Method for estimating needs",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      },
      "CS-5": {
        "value": null,
        "children": {
          "1": {
            "value": null,
            "children": {
              "1": {
                "value": "Ideal/reference body weight (IBW)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "2": {
                "value": "Recommended body mass index (BMI)",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "3": {
                "value": "Goal weight",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "4": {
                "value": "Goal weight gain/day",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "5": {
                "value": "Goal weight-for-length z score",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "6": {
                "value": "Goal mid upper arm circumference z score",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "7": {
                "value": "Goal BMI-for-age z score",
                "children": {},
                "type": "checkbox", "checked": false
              },
              "8": {
                "value": "Percent median BMI",
                "children": {},
                "type": "checkbox", "checked": false
              }
            }
          }
        }
      }
    }
  }
}