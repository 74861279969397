import { Helmet } from "react-helmet";
import RegisterForm from "../../components/SigninForm/RegisterForm";
import "./RegisterPage.scss";

const RegisterPage = () => {
    return (
        <div className="register-page-container">
            <Helmet>
                <title>Phoenix Lounge - Registration</title>
                <meta name="robots" content="index, follow" />
                <meta
                    name="description"
                    content="Register to sign up for the Phoenix Lounge Omni Wellness Mobile practice. Monitor your progress today."
                />
                <meta name="twitter:title" content="Phoenix Lounge - Registration" />
                <meta name="twitter:description" content="Register to sign up for the Phoenix Lounge Omni Wellness Mobile practice. Monitor your progress today." />
                <meta property="og:title" content="Phoenix Lounge - Registration" />
                <meta property="og:description" content="Register to sign up for the Phoenix Lounge Omni Wellness Mobile practice. Monitor your progress today." />
            </Helmet>
            <RegisterForm />
        </div>
    )
}

export default RegisterPage;