import { useEffect, useState } from 'react';
import * as api from "../../apis/";
import "./DefinitionsPage.scss";
import DefinitionItem from '../../components/DefinitionItem/DefinitionItem';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
const DefinitionsPage = () => {
    const navigate = useNavigate();
    const [definitions, setDefinitions] = useState([] as {title: string, contents: string[], loc: number}[]);
    const [loaded, setLoaded] = useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const onSuccess = (data:any) => {
        const myDefinitions = data.definitions.map((v:any)=>{return {...v, contents: v.contents.split("\n"), loc: parseFloat(v.loc)}});
        myDefinitions.sort((a:any,b:any)=>a.loc - b.loc);
        setDefinitions(myDefinitions);
        setLoaded(true);
    }
    const onFailure = (data:any) => {
        closeSnackbar();
        enqueueSnackbar("Definitions could not be loaded, try again.");
        setLoaded(true);
    }
    useEffect(()=>{
        //Make api call.
        api.helpers.getApi(api.routes.getDefinitions, onSuccess, onFailure);
    },[])
    if(!loaded){
        return <CircularProgress />
    }
    return (
        <div className="definitions-page-content">
            <Helmet>
                <title>Phoenix Lounge - Definitions</title>
                <meta name="robots" content="index, follow" />
                <meta
                    name="description"
                    content="Definitions to help our guests and users understand terminology of the Phoenix Lounge Mobile Wellness Center."
                />
                <meta name="twitter:title" content="Phoenix Lounge - Definitions" />
                <meta name="twitter:description" content="Definitions to help our guests and users understand terminology of the Phoenix Lounge Mobile Wellness Center." />
                <meta property="og:title" content="Phoenix Lounge - Definitions" />
                <meta property="og:description" content="Definitions to help our guests and users understand terminology of the Phoenix Lounge Mobile Wellness Center." />
            </Helmet>
            <div className="definition-instruction-header">
                <div>
                    <p>Click to expand or collapse a definition.</p>
                </div>
            </div>
            {
                definitions.map((definition)=>(<DefinitionItem definition={definition}/>))
            }
        </div>
    )
};

export default DefinitionsPage;