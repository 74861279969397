import { useState, useEffect } from 'react'
import { X } from 'lucide-react'
import Confetti from 'react-confetti'
import { Button } from "@mui/material";
import "./SpecialOfferPopup.scss";
import { useNavigate } from 'react-router-dom';

export default function SpecialOfferPopup() {
    const [showPopup, setShowPopup] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

    const navigate = useNavigate()
  
    useEffect(() => {
      const hasSeenPopup = localStorage.getItem('hasSeenPopup')
      if (!hasSeenPopup) {
        setShowPopup(true)
        localStorage.setItem('hasSeenPopup', 'true')
      }
  
      const updateWindowDimensions = () => {
        setWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
      }
  
      updateWindowDimensions()
      window.addEventListener('resize', updateWindowDimensions)
  
      return () => window.removeEventListener('resize', updateWindowDimensions)
    }, [])
  
    const closePopup = () => {
      setShowPopup(false)
    }

    const handleClick = () => {
      navigate('/register')
    }
  
    if (!showPopup) return null
  
    return (
      <div className="popup-overlay">
        <Confetti
          width={windowDimensions.width}
          height={windowDimensions.height}
          colors={['#009fff', '#ffffff']}
        />
        <div className="popup-container">
          <button
            onClick={closePopup}
            className="close-button"
            aria-label="Close popup"
          >
            <X size={24} />
          </button>
          <h2 className="popup-title">Special Offer!</h2>
          <p className="popup-text">
            Now taking new patients walk-ins via the mobile wellness center at $50 special!
          </p>
          <p className="popup-text">
            Start with Registration Online now, and schedule an in-person or remote-visit today!
          </p>
          <p className="popup-text">
            Assessments can be done within 2 hours so travelers are not spending too much time stationary.
          </p>
          <div className="button-container">
            <Button
              onClick={handleClick}
              className="get-started-button"
              variant="contained"
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    )
  }