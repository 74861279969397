import { Helmet } from "react-helmet";
import ContactForm from "../../components/ContactForm/ContactForm";
import "./ContactUsPage.scss";

const ContactUsPage = () => {
    return (
        <div className="contact-us-page-container">
            <Helmet>
                <title>Phoenix Lounge - Contact Us</title>
                <meta name="robots" content="index, follow" />
                <meta
                    name="description"
                    content="Contact the Phoenix Lounge Mobile wellness center. Omni wellness Christ centered wellness practice."
                />
                <meta name="twitter:title" content="Phoenix Lounge - Contact Us" />
                <meta name="twitter:description" content="Contact the Phoenix Lounge Mobile wellness center. Omni wellness Christ centered wellness practice." />
                <meta property="og:title" content="Phoenix Lounge - Contact Us" />
                <meta property="og:description" content="Contact the Phoenix Lounge Mobile wellness center. Omni wellness Christ centered wellness practice." />
            </Helmet>
            <ContactForm />
        </div>
    )
}

export default ContactUsPage;