import axios from 'axios';
import { initUserInfo } from '../App';

const baseConfig = {withCredentials: true, credentials: 'include'};

export const postAuthApi = async (route: string, postData:any, successCallback: (data: any) =>void, failureCallback: (data: any) =>void, navigate=(key:string) =>{console.log('a')}, config={withCredentials: true, credentials: 'include', headers: { "Authorization": `${JSON.parse(sessionStorage.getItem('user')||"{sessionId: 'Bearer'}").sessionId} ${sessionStorage.getItem('authToken')}` }} as any) => {
    try {
        const { data } = await axios.post(route, postData, config);
        successCallback(data);
    } catch (err:any) {
        let message = (err && err.response && err.response.data && err.response.data.message)?err.response.data.message:{type: "ERROR", status: 500, msg: "An unknown error has occurred."};
        if(message.type==='SIGNEDOUT'){
            sessionStorage.clear()
            failureCallback({message: message});
            navigate('/login');
        } else if(message.type==='EXPIREDAUTH'){
            try{
                const { data: dataa } = await axios.get('/api/auth/renewKeys', baseConfig);
                sessionStorage.setItem("authToken", dataa.authToken);
                sessionStorage.setItem("user", JSON.stringify(dataa.user));
                const { data } = await axios.post(route, postData, {...config, headers: {...config.headers, "Authorization": `${JSON.parse(sessionStorage.getItem('user')||"{sessionId: 'Bearer'}").sessionId} ${dataa.authToken}`}});
                successCallback(data);
            } catch (err:any){
                failureCallback({message: message});
            }
        } else {
            
            failureCallback({message: message});
        }
    }
};
export const getAuthApi = async (route: string, successCallback: (data: any) =>void, failureCallback: (data: any) =>void, navigate=(key:string):void=>{}, config={withCredentials: true, credentials: 'include', headers: { "Authorization": `${JSON.parse(sessionStorage.getItem('user')||"{\"sessionId\": \"Bearer\"}").sessionId} ${sessionStorage.getItem('authToken')}` }} as any) => {
    try {
        const { data } = await axios.get(route, config);
        successCallback(data);
    } catch (err:any) {
        try{
            let message = (err && err.response && err.response.data && err.response.data.message)?err.response.data.message:{type: "ERROR", status: 500, msg: "An unknown error has occurred."};
            if(message.type==='SIGNEDOUT'){
                sessionStorage.clear()
                failureCallback({message: message});
                navigate('/login');
            } else if(message.type==='EXPIREDAUTH'){
            try{
                const { data: dataa } = await axios.get('/api/auth/renewKeys', baseConfig);
                sessionStorage.setItem("authToken", dataa.authToken);
                sessionStorage.setItem("user", JSON.stringify(dataa.user));
                const { data } = await axios.get(route, {...config, headers: {...config.headers, "Authorization": `${JSON.parse(sessionStorage.getItem('user')||"{\"sessionId\": \"Bearer\"}").sessionId} ${dataa.authToken}`}});
                successCallback(data);
            } catch (err:any){
                failureCallback({message: message});
            }
        } else {
            failureCallback({message: message});
        }
    } catch(err:any){
        failureCallback({message: {type: "ERROR", status: 500, msg: "An unknown error has occurred."}});
    }
    }
}
export const getAccessApi = async (route: string, successCallback: (data: any) =>void, failureCallback: (data: any) =>void, setUserInfo=(key:any) =>{console.log('a')}, config={withCredentials: true, credentials: 'include'} as any) => {
    try{
        const { data } = await axios.get(route);
        successCallback(data);
    } catch (err:any) {
        let message = (err && err.response && err.response.data&&err.response.data.message)?err.response.data.message:{type: "ERROR", msg: "An unknown error has occurred"};
        failureCallback(message);
    }
}
export const downloadAccessApi = async (route: string, successCallback: (data: any) =>void, failureCallback: (data: any) =>void, config={withCredentials: true, credentials: 'include'} as any) => {
    try{
        const { data, headers } = await axios.get(route, {...config, responseType: 'blob'});
        const href = URL.createObjectURL(data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        console.log(headers);
        let fileName = headers['content-disposition'].split("filename=\"")[1];
        console.log(fileName);
        fileName = fileName.substring(0,fileName.length-1);
        console.log(fileName);
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        successCallback(fileName);
    } catch (err:any) {
        console.log(err);
        let message = (err && err.response && err.response.data&&err.response.data.message)?err.response.data.message:{type: "ERROR", msg: "An unknown error has occurred"};
        failureCallback(message);
    }
}
export const postApi = async (route: string, postData:any, successCallback: (data:any) => void, failureCallback: (data:any) => void) => {
    try {
        const { data} = await axios.post(route, postData, { headers: {'Content-Type': 'application/json'}});
        successCallback(data);
    } catch (err:any) {
        let message = err;
        failureCallback(message);
    }
}
export const getApi = async (route: string, successCallback: (data:any) => void, failureCallback: (data:any) => void) => {
    try {
        const { data} = await axios.get(route, {withCredentials: true});
        successCallback(data);
    } catch (err:any) {
        let message = (err && err.response && err.response.data && err.response.data.message)?err.response.data.message:{type: "ERROR", status: 500, msg: "An unknown error has occurred."};
        failureCallback(message);
    }
}

export const postAccessApi = async (route: string, postData: any, successCallback: (data:any) =>void, failureCallback: (data: any) =>void, config={withCredentials: true, credentials: 'include'} as any) => {
    try{
        const { data } = await axios.post(route, postData, {headers: {'Content-Type': 'application/json'}, ...config});
        successCallback(data);
    } catch (err:any) {
        console.log(err);
        let message = (err && err.response && err.response.data&&err.response.data.message)?err.response.data.message:{type: "ERROR", msg: "An unknown error has occurred"};
        failureCallback(message);
    }
}

//New functions
export const postAccessFormData = async (route: string, postData:any, successCallback: (data: any) =>void, failureCallback: (data: any) =>void, config={withCredentials: true, credentials: 'include'} as any) => {
    try{
        const { data } = await axios.post(route, postData, {headers: {'Content-Type': 'multipart/form-data'}, ...config});
        successCallback(data);
    } catch (err:any) {
        let message = (err && err.response && err.response.data&&err.response.data.message)?err.response.data.message:{type: "ERROR", msg: "An unknown error has occurred"};
        failureCallback(message);
    }
}

export const postAuthFormData = async (route: string, postData:any, successCallback: (data: any) =>void, failureCallback: (data: any) =>void, config={withCredentials: true, credentials: 'include', headers: {'Content-Type': 'multipart/form-data', "Authorization": `${JSON.parse(sessionStorage.getItem('user')||"{\"sessionId\": \"Bearer\"}").sessionId} ${sessionStorage.getItem('authToken')}` }} as any) => {
    console.log(config);
    try{
        const { data } = await axios.post(route, postData, config);
        successCallback(data);
    } catch (err:any) {
        //
        let message = (err && err.response && err.response.data && err.response.data.message)?err.response.data.message:{type: "ERROR", status: 500, msg: "An unknown error has occurred."};
        if(message.type==='SIGNEDOUT'){
            sessionStorage.clear()
            failureCallback({message: message});
        } else if(message.type==='EXPIREDAUTH'){
            try{
                const { data: dataa } = await axios.get('/api/auth/renewKeys', config);
                sessionStorage.setItem("authToken", dataa.authToken);
                sessionStorage.setItem("user", JSON.stringify(dataa.user));
                const { data } = await axios.post(route, postData, {...config, headers: {...config.headers, "Authorization": `${JSON.parse(sessionStorage.getItem('user')||"{sessionId: 'Bearer'}").sessionId} ${dataa.authToken}`}});
                successCallback(data);
            } catch (err:any){
                failureCallback({message: message});
            }
        } else {
            
            failureCallback({message: message});
        }
        // if(message.type==="SIGNEDOUT"){
        //     sessionStorage.clear();
        //     setUserInfo(initUserInfo);
        // } else if (message.type==="REQUIRESPIN"){
        //     navigate('/user/confirm-pin?redirect='+encodeURI(window.location.href));
        // }
    }
}