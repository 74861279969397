import { Helmet } from "react-helmet";
import SigninForm from "../../components/SigninForm/SigninForm";
import "./SigninPage.scss";

const SigninPage = () => {
    return (
        <div className="signin-page-container">
            <Helmet>
                <title>Phoenix Lounge - Sign In</title>
                <meta name="robots" content="index, follow" />
                <meta
                    name="description"
                    content="Sign In to the Phoenix Lounge Omni Wellness Portal to access your services, messages and reports."
                />
                <meta name="twitter:title" content="Phoenix Lounge - Sign In" />
                <meta name="twitter:description" content="Sign In to the Phoenix Lounge Omni Wellness Portal to access your services, messages and reports." />
                <meta property="og:title" content="Phoenix Lounge - Sign In" />
                <meta property="og:description" content="Sign In to the Phoenix Lounge Omni Wellness Portal to access your services, messages and reports." />
            </Helmet>
            <SigninForm />
        </div>
    )
}

export default SigninPage;