
import { useContext, useEffect, useRef, useState } from "react";
import "./SigninForm.scss";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../../utils/context";
import * as api from '../../apis/';
import { useSnackbar } from "notistack";
import { isValidEmail, isValidPhone } from "../../utils/helpers";
import { Button } from "@mui/material";
const ForgotPasswordForm = () => {
    const emailInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);
    const confirmNewPassInput = useRef<HTMLInputElement>(null);
    const codeInput = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [isPosting, setIsPosting] = useState(false);
    const {userInfo, setUserInfo} = useContext(UserInfoContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [step, setStep] = useState(0);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSuccess = (data:any) => {
        setUserInfo(data.user);
        sessionStorage.setItem("authToken", data.authToken);
        navigate('/');
    }
    const handleFailure = (data:any) => {
        closeSnackbar();
        enqueueSnackbar("Invalid Email/Phone and Password Combination.", {variant: 'error'});
        setIsPosting(false);
    }
    const handleSubmit = (e:any) => {
        e.preventDefault();
        if(passwordInput.current?.value!==confirmNewPassInput.current?.value) {
            enqueueSnackbar("Password's do not match", {variant: 'error'});
            return;
        }
        let submitEmail = emailInput.current?.value;
        let password = passwordInput.current?.value;
        let code = codeInput.current?.value;
        api.helpers.postApi(api.routes.resetpasswithcode, {email: submitEmail, password: password, code: code}, (data)=>{
            if(data.success){
                enqueueSnackbar("Password reset successfully. Please sign in.", {variant: 'success'});
                navigate('/signin');
            } else{
                enqueueSnackbar("Could not reset password, please try again with a different email/code", {variant: 'error'});
            }
            setIsPosting(false);
        },(data)=>{
            enqueueSnackbar("Could not reset password. Please try again with a different email/code", {variant: 'error'});
            setIsPosting(false);
        })//Reset passsword needs to be set in the api routes.
        //api.helpers.postApi(api.routes.signin, {email: submitEmail, password: passwordInput.current?.value}, handleSuccess, handleFailure);
    }
    useEffect(()=>{
        if(userInfo.permissions[0]==="1") navigate('/');
    },[userInfo])
    const handleSend = () => {
        if(isSubmitting) return
        setIsSubmitting(true);
        api.helpers.postApi(api.routes.forgotpass, {email: emailInput.current?.value}, (data)=>{
            setStep(1);
            enqueueSnackbar("Sent! Check your email, make sure to check spam folder as well.", {variant: 'success'});
            setIsSubmitting(false);
        }, (data)=>{
            enqueueSnackbar("Could not send email at this time. Try again later", {variant: 'error'});
            setIsSubmitting(false);
        })
    }
    const handleConfirmCode = () => {
        if(isSubmitting) return
        setIsSubmitting(true);
        api.helpers.postApi(api.routes.confirmresetcode, {email: emailInput.current?.value, code: codeInput.current?.value}, (data)=>{
            if(data.valid){
                setStep(2);
                enqueueSnackbar("Enter your new password below and confirm it.", {variant: 'success'});
            } else{
                enqueueSnackbar("Code is invalid or expired", {variant: 'error'});
            }
            setIsSubmitting(false);
        }, (data)=>{
            enqueueSnackbar("Code is invalid or expired.", {variant: 'error'});
            setIsSubmitting(false);
        })
    }
    return (
        <div className="signin-area">
            <div className="signinForm">
                <form onSubmit={handleSubmit}>
                    <h2>Forgot Password</h2>
                    {step>=0?(<>
                    <div className="inputBox">
                        <input type="text" name="emailphone" required ref={emailInput}/>
                        <span onClick={()=>{emailInput.current?.focus()}}>Email</span>
                    </div>
                    <Button variant="outlined" onClick={handleSend}>Send Email</Button>
                    </>):''}
                    {(step>=1?(<>
                    <p style={{color: 'white'}}>Check your email for reset code and enter it here:</p>
                        <div className="inputBox">
                            <input type="text" required ref={codeInput} />
                            <span onClick={()=>{codeInput.current?.focus()}}>Code</span>
                        </div>
                        <Button variant="outlined" onClick={handleConfirmCode}>Confirm Code</Button>
                    </>):'')}
                    {(step===2?((<>
                        <div className="inputBox">
                            <input type="password" required ref={passwordInput} />
                            <span onClick={()=>{passwordInput.current?.focus()}}>New Password</span>
                        </div>
                        <div className="inputBox">
                            <input type="password" required ref={confirmNewPassInput} />
                            <span onClick={()=>{confirmNewPassInput.current?.focus()}}>Confirm New Password</span>
                        </div>
                    <div className="inputBox">
                        <input type="submit" name="submit-btn" value="Update Password" disabled={isPosting}/>
                    </div>
                    </>)):'')}
                </form>
            </div>
        </div>
    )
};

export default ForgotPasswordForm;